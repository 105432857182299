import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["src", "onClick"]
const _hoisted_2 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_ion_title, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('updateBooking')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_title, { key: 1 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('newBooking')), 1)
                  ]),
                  _: 1
                })),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, { class: "ion-margin-bottom" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('services')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.build,
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { position: "floating" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('services')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_select, {
                  modelValue: _ctx.booking.targetService,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.booking.targetService) = $event)),
                  multiple: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service) => {
                      return (_openBlock(), _createBlock(_component_ion_select_option, {
                        key: service.id,
                        value: service.title
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(service.title), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.chatboxEllipsesOutline,
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('description')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_textarea, {
                  modelValue: _ctx.booking.customerNote,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.booking.customerNote) = $event))
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('photo')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_row, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.photos, (photo) => {
                  return (_openBlock(), _createBlock(_component_ion_col, {
                    size: "4",
                    key: photo
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: photo.base64Data,
                        onClick: ($event: any) => (_ctx.showActionSheet(photo))
                      }, null, 8, _hoisted_1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_ion_col, { size: "4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_card, { class: "photo-card ion-align-items-center ion-justify-content-center ion-text-center" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_fab_button, {
                          class: "take-photo-fab-btn",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.takePhoto()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, { icon: _ctx.camera }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { class: "ion-margin-bottom" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('CheckoutPage.contactInfo')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Multiselect, {
                modelValue: _ctx.booking.address,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.booking.address) = $event)),
                placeholder: _ctx.t('selectOrEnterAddress'),
                options: _ctx.getLocationOptions(_ctx.userLocations),
                searchable: true,
                createTag: true
              }, null, 8, ["modelValue", "placeholder", "options"])
            ]),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.person,
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('customerName')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterCustomerName'),
                  modelValue: _ctx.booking.customerName,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.booking.customerName) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.call,
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('contactPhone')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterContactPhone'),
                  inputmode: "numeric",
                  modelValue: _ctx.booking.contactPhone,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.booking.contactPhone) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  icon: _ctx.mail,
                  slot: "start"
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, { position: "stacked" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('customerEmail')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_input, {
                  placeholder: _ctx.t('CheckoutPage.enterCustomerEmail'),
                  type: "email",
                  modelValue: _ctx.booking.customerEmail,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.booking.customerEmail) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_list, { class: "ion-margin-bottom" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, { color: "primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('bookingDetails')), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.calendar,
                    slot: "start"
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('bookingDate')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    placeholder: _ctx.t('selectBookingDate'),
                    interface: "action-sheet",
                    modelValue: _ctx.booking.date,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.booking.date) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableDates, (date) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: date.value,
                          value: date.value
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(date.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["placeholder", "modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.time,
                    slot: "start"
                  }, null, 8, ["icon"]),
                  _createVNode(_component_ion_label, { position: "stacked" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('bookingTime')), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_select, {
                    placeholder: _ctx.t('selectBookingTime'),
                    interface: "popover",
                    modelValue: _ctx.booking.selectedTimeRange,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.booking.selectedTimeRange) = $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.availableTimeSlots, (timeSlot) => {
                        return (_openBlock(), _createBlock(_component_ion_select_option, {
                          key: timeSlot,
                          value: timeSlot
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(timeSlot), 1)
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["placeholder", "modelValue"])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.isEditing)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 0,
                  expand: "block",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.updateBooking()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('updateBooking')), 1)
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_ion_button, {
                  key: 1,
                  expand: "block",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.createNewBooking(_ctx.booking)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('createBooking')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}