
// Vue reactivity
import { ref, reactive, defineComponent, watchEffect, computed, watch } from 'vue';

// icons
import { add, close, build, camera, location, person, call, mail, calendar, time, chatboxEllipsesOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
        IonSpinner, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
        IonList, IonListHeader, IonDatetime, IonCheckbox, IonSelect, IonSelectOption,
        IonRow, IonCol, IonFabButton, IonCard,
        modalController, loadingController, } from '@ionic/vue';
import Multiselect from '@vueform/multiselect'

// API services
import BookingService from '@/services/BookingService';

import moment from 'moment';
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { usePhotoGallery } from '@/composables/usePhotoGallery';

export default defineComponent({
  name: 'BookingModal',
  props: {
    editingBooking: null,
  },
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonSpinner, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonAvatar, IonButtons, IonButton, IonInput, IonTextarea,
                IonList, IonListHeader, IonDatetime, IonCheckbox, IonSelect, IonSelectOption,
                IonRow, IonCol, IonFabButton, IonCard, Multiselect },
  setup(props) {
    const { photos, takePhoto, showActionSheet } = usePhotoGallery();
    const store = useStore();
    const { t } = useI18n();
    const { formatDate, presentToast, } = utils();
    
    // 1. declare state variables (ref to make them reactive)
    const user = computed(() => store.state.user);
    const userLocations = computed(() => store.state.userLocations);
    const booking = reactive({
      id: null,
      address: "",
      customerName: user.value.firstName || "",
      contactPhone: user.value.phone || "",
      customerEmail: user.value.email || "",
      date: "",
      selectedTimeRange: "",
      customerNote: "",
      targetService: "",
      locationId: "",
    });
    const services = computed(() => store.state.bookingServices);
    const availableTimeSlots = computed(() => store.state.bookingTimeSlots);
    const availableDates: any = ref([]);
    const isEditing = ref(false);

    const minDate = moment(), maxDate = moment().add(14, 'days'), dates = [];
    for (let m = moment(minDate); m.isBefore(maxDate); m.add(1, 'days')) {
      dates.push( { text: m.format('YYYY-MM-DD (ddd)'), value: m.format('MM/DD/YYYY') } );
    }
    availableDates.value = dates;

    if (props.editingBooking) { // editing booking
      isEditing.value = true;
      watchEffect(() => {
        const { id, customerName, contactPhone, customerEmail } = props.editingBooking.value;
        booking.id = id;
        booking.customerName = customerName;
        booking.contactPhone = contactPhone;
        booking.customerEmail = customerEmail;
      })
    }
    watch(user, (currUser) => { // triggered only when direct access to this page
      booking.customerName = currUser.firstName;
      booking.contactPhone = currUser.phone;
      booking.customerName = currUser.email;
    });

    // methods or filters
    const closeModal = async (data: any = {}) => {
      await modalController.dismiss(data);
    };

    const createNewBooking = async (booking: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      booking.locationId = store.getters.getLocationIdFromAddress(booking.address);
      BookingService.createNewBooking(booking, photos.value).then((res) => {
        store.commit('addBooking', res);
      });
      setTimeout(() => {
        presentToast(t('successCreateBooking')); // bookings
        closeModal();
        loading.dismiss();
        store.commit('setLoadingBookings', true);
      }, 3000)
    };

    const updateBooking = async() => {
      const loading = await loadingController.create({});
      await loading.present();
      const res = BookingService.updateBooking();
      loading.dismiss();
      presentToast(t('successUpdateBooking'));
      closeModal();
    }

    const getLocationOptions = (locations: any) => {
      return locations.map((ul: any) => {
        const prefix = ul.floor ? `${ul.locationName}${ul.floor}樓${ul.unit}室` : `${ul.locationName}`;
        return { value: `${ul.locationAddress}${prefix}`, label: `[${prefix}] ${ul.locationAddress}` }
      });
    }

    if (userLocations.value) {
      // prefill the address (normally users should have 1 address only)
      const selectedOption = getLocationOptions(userLocations.value)[0];
      if (selectedOption) booking.address = selectedOption.value;
    }

    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons
      add, close, build, camera, location, person, call, mail, calendar, time, chatboxEllipsesOutline,

      // variables
      isEditing, booking, services, availableDates, availableTimeSlots,
      userLocations,
      photos,

      // methods
      formatDate, closeModal, createNewBooking, updateBooking,
      getLocationOptions,
      takePhoto, showActionSheet
    }
  }
});
