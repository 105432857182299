import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d97afe0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "bookingStatusFilter",
  style: {"text-align":"center"}
}
const _hoisted_2 = { style: {"font-size":"0.998em","font-weight":"600","color":"#fda619","padding-bottom":"3px"} }
const _hoisted_3 = {
  class: "description",
  style: {"font-size":"1.248em","font-weight":"bold","color":"#4D4D4D","padding-bottom":"3px"}
}
const _hoisted_4 = {
  class: "description",
  style: {"font-size":"0.936em","color":"#6c6b6c","padding-bottom":"3px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, {
            fixed: "",
            style: {"padding":"0"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, {
                    align: "center",
                    style: {"padding":"25px"}
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($setup.t('bookings')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_fab, {
        horizontal: "end",
        vertical: "bottom",
        slot: "fixed"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openNewBookingModal()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                style: {"font-size":"35px"},
                icon: $setup.add
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => ($setup.fetchUserBookings($event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          _createVNode(_component_ion_grid, { fixed: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { mode: "md" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_ion_chip, {
                      class: _normalizeClass({ 'chip-background': $setup.bookingStatusFilter == 'ongoing' }),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.setBookingStatusFilter('ongoing'))),
                      outline: $setup.bookingStatusFilter != 'ongoing'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('ongoing')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class", "outline"]),
                    _createVNode(_component_ion_chip, {
                      class: _normalizeClass({ 'chip-background': $setup.bookingStatusFilter == 'completed' }),
                      onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.setBookingStatusFilter('completed'))),
                      outline: $setup.bookingStatusFilter != 'completed'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('completed')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class", "outline"]),
                    _createVNode(_component_ion_chip, {
                      class: _normalizeClass({ 'chip-background': $setup.bookingStatusFilter == 'cancelled' }),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.setBookingStatusFilter('cancelled'))),
                      outline: $setup.bookingStatusFilter != 'cancelled'
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('cancelled')), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }, 8, ["class", "outline"])
                  ])
                ]),
                _: 1
              }),
              ($setup.loading)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (index) => {
                        return _createVNode(_component_ion_item, { key: index }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_skeleton_text)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("h3", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"80%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"60%"}
                                  })
                                ]),
                                _createElementVNode("p", null, [
                                  _createVNode(_component_ion_skeleton_text, {
                                    animated: "",
                                    style: {"width":"30%"}
                                  })
                                ])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 2
                        }, 1024)
                      }), 64))
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_list, {
                    key: 1,
                    class: "dataLoaded",
                    align: "center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_row, null, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.bookings, (booking) => {
                            return _withDirectives((_openBlock(), _createBlock(_component_ion_col, {
                              "size-xs": "12",
                              "size-md": "6",
                              "size-lg": "6",
                              key: booking.id
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_chip, {
                                  "router-link": {
              name: 'BookingDetailPage',
              params: { id: booking.id }
            },
                                  outline: ""
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_label, null, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h2", _hoisted_2, _toDisplayString(booking.code), 1),
                                        _createElementVNode("h2", _hoisted_3, _toDisplayString(booking.targetService), 1),
                                        _createElementVNode("p", _hoisted_4, _toDisplayString($setup.formatDateString(booking.date)) + " (" + _toDisplayString(booking.selectedTimeRange) + ")", 1),
                                        _createVNode(_component_ion_badge, {
                                          color: $setup.getBookingStatusColor(booking.status),
                                          style: {"border-radius":"5px"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString($setup.t(`BookingStatus.${booking.status}`)), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1032, ["router-link"])
                              ]),
                              _: 2
                            }, 1024)), [
                              [_vShow, $setup.needShowBooking(booking, $setup.bookingStatusFilter)]
                            ])
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}